import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

    return (
    <Box {...other}>
        <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
            <Box>
                <NavItem key={item.id} item={item} />
                <NavChildrenSection children={item} />
            </Box>
        ))}
        </List>
    </Box>
  );
}

function NavChildrenSection({ children = [], ...other }) {
    return (
        <Box {...other}>
            <List style={{marginLeft:'50px'}}>
                {(children.children).map((child) => (
                    <NavItem key={child.id} item={child} />
                ))}
            </List>
        </Box>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

NavChildrenItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, children} = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}


function NavChildrenItem({ item }) {
    const { title, path, icon } = item;

    return (
        <StyledNavItem
            component={RouterLink}
            to={path}
            sx={{
                '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                },
            }}
        >
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

            <ListItemText disableTypography primary={title} />

        </StyledNavItem>
    );
}

