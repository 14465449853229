import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import base64 from "base-64";
import {useMediaQuery} from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {styled} from "@mui/material/styles";
// components
// sections

// mock
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {getSlfs, addSLF} from "../axios/SLF";
import {getClients} from "../axios/Client";


// ----------------------------------------------------------------------


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

// ----------------------------------------------------------------------


export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    return <>{isMobile && children}</>
}

export const PC = ({children}) => {
    const isPc = useMediaQuery({
        query: "(min-width:769px)"
    });

    return <>{isPc && children}</>
}


export default function SLFManagingPage() {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecordCount,setTotalRecordCount] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [clientList,setClientList] = useState([]);


    const [visitDate, setVisitDate] = useState(new Date());

    const [HWVal, setHWVal] = React.useState('');
    const handleHWChange = (event: SelectChangeEvent) => {
        setHWVal(event.target.value);
    };

    const [SWVal, setSWVal] = React.useState('');
    const handleSWChange = (event: SelectChangeEvent) => {
        setSWVal(event.target.value);
    };

    const [OPVal, setOPVal] = React.useState('');
    const handleOPChange = (event: SelectChangeEvent) => {
        setOPVal(event.target.value);
    };


    const [manager, setManager] = React.useState('');
    const handleManagerChange = (event: SelectChangeEvent) => {
        setManager(event.target.value);
    };
    const [memo, setMemo] = React.useState('');
    const handleMemoChange = (event: SelectChangeEvent) => {
        setMemo(event.target.value);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const [clientVal, setClientVal] = React.useState('');
    const handleClientChange = (event: SelectChangeEvent) => {
        setClientVal(event.target.value);
    };

    const [contents, setContents] = useState([]);

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#3F51B5',
            color: theme.palette.common.white,
            border: '1px solid white',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            textOverflow: 'scroll',
            minWidth: 100,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const colorDot = (color) => {
        if(color==="GREEN"){
            return (
               <span style={{color: 'rgb(54, 200, 150)', marginRight:12}}>●</span>
            )
        }
        if(color==="YELLOW"){
            return (
                <span style={{color: 'rgb(255, 205, 86)', marginRight:12}}>●</span>
            )
        }
            return (
                <span style={{color: 'rgb(255, 72, 66)', marginRight:12}}>●</span>
            )

    };


    useEffect(() => {
        async function fetchData() {
            try {
                const slfs = await getSlfs({page, rowsPerPage});
                const clients = await getClients({page, rowsPerPage});
                setContents(slfs.data.content);
                setClientList(clients.data.content);
                setTotalRecordCount(slfs.data.totalRecordCount);
                console.log(contents);
            } catch (error) {
                console.log(error); // 비동기 함수의 결과 처리
            }
        }

        fetchData();
    }, [rowsPerPage,page]);

    const postSLF = async () => {
        await addSLF({clientVal,HWVal,SWVal,OPVal,manager,visitDate,memo}).then(
            (res) => {
                if (res != null) {
                    handleClose();
                    // window.location.reload();
                }
            });
    }
    const token = localStorage.getItem("accessToken");
    let payload = null;
    let auth = null;
    if(token!==null){
        payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
        auth = JSON.parse(base64.decode(payload)).auth;
    }

    const SDatePicker = styled(DatePicker)(({theme}) => ({
        marginBottom:30,
        width: 300,
        height:50,
        boxSizing:'border-box',
        padding: '8px 20px',
        borderRadius: '4px',
        border: '1px solid lightgray',
        fontSize: 14,
    }));

    const buttonYN = () =>{

        if(auth === 1 || auth === 2){
            return (
                <PC>
                    <Grid>
                        <Button onClick={handleOpen} color="secondary" variant="contained">
                            사후 관리 등록
                        </Button>
                    </Grid>
                </PC>
            )
        }
        return null;
    }

    return (
        <>
            <Helmet>
                <title> SLF 관리 </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        SLF 관리
                    </Typography>
                    {buttonYN()}
                </Stack>


                <PC>
                    <Container sx={{ mb: 6 }} style={{ display: 'flex' }} >
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} >
                            <span style={{color: 'rgb(54, 200, 150)', marginRight:12}}>●</span>정상동작 및 운영(활용도 양호) <br />
                            <span style={{color: 'rgb(255, 205, 86)', marginRight:12}}>●</span>정상동작 가능/성능저하 및 운영(활용도 보통) <br />
                            <span style={{color: 'rgb(255, 72, 66)', marginRight:12}}>●</span>정상동작 불가/성능저하 및 운영(활용도 불량) <br />
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} style={{ marginLeft:'auto' }}>
                            H/W : 하드웨어 <br />
                            S/W : 소프트웨어 <br />
                            OP : Operational <br />
                        </Typography>
                    </Container>
                </PC>
                <Mobile>
                    <Container sx={{ mb: 4 }} style={{ display: 'flex' }} >
                        <Typography variant="body2" sx={{color: 'text.secondary'}} fontSize={'3vw'}  style={{marginRight:'2%'}}>
                            <span style={{color: 'rgb(54, 200, 150)', marginRight: 2}}>●</span>정상동작 및 운영<br/>(활용도
                            양호) <br/>
                            <span style={{color: 'rgb(255, 205, 86)', marginRight: 2}}>●</span>정상동작 가능/성능저하 및
                            운영<br/>(활용도 보통) <br/>
                            <span style={{color: 'rgb(255, 72, 66)', marginRight: 2}}>●</span>정상동작 불가/성능저하 및 운영<br/>(활용도
                            불량) <br/>
                        </Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}}
                                    style={{marginLeft:'auto'}} fontSize={'3vw'}>
                            H/W : <br/> 하드웨어 <br/>
                            S/W : <br/> 소프트웨어 <br/>
                            OP : <br/> Operational <br/>
                        </Typography>
                    </Container>
                </Mobile>

                <Card>
                    <TableContainer style={{whiteSpace:'pre-wrap'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" rowSpan={2}>ID</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>고객사</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={3}>Condition</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>담당자</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>메모</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>방문일</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>등록일</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="center">H/W</StyledTableCell>
                                    <StyledTableCell align="center">S/W</StyledTableCell>
                                    <StyledTableCell align="center">OP</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contents.map((content) => (
                                    <StyledTableRow key={content.id}>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {content.id}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center">{content.client.name}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{colorDot(content.hwStatus)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{colorDot(content.swStatus)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{colorDot(content.opStatus)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{content.manager}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{content.memo}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{(content.lastVisitedDate).toString().slice(0, 10)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{(content.createdDate).toString().slice(0, 10)}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={totalRecordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description">
                    <Box sx={{...style, width: '50%'}}>
                        <h2 id="parent-modal-title" style={{marginBottom: 30}}>사후 관리 등록</h2>
                        <FormControl sx={{width: '100%'}} style={{marginBottom: 20}}>
                            <InputLabel id="demo-simple-select-required-label">고객사</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={clientVal}
                                label="고객사 *"
                                onChange={handleClientChange}>
                                {clientList.map((client) => (
                                    <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography color="secondary" variant="subtitle1" gutterBottom>
                            Condition
                        </Typography>
                        <Grid style={{display: 'flex'}}>
                            <Grid style={{width:'33%'}}>
                            <Typography variant="body2" style={{marginBottom: 10}}>
                                H/W(하드웨어)
                            </Typography>
                            <FormControl  style={{ marginBottom: 20, width:'33%'}}>
                                <InputLabel id="demo-simple-select-required-label">상태</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={HWVal}
                                    onChange={handleHWChange}
                                    label="Condition *">
                                    <MenuItem key={'green'} value={'GREEN'}><span style={{color: 'rgb(54, 200, 150)', marginRight:12}}>●</span></MenuItem>
                                    <MenuItem key={'yellow'} value={'YELLOW'}><span style={{color: 'rgb(255, 205, 86)', marginRight:12}}>●</span></MenuItem>
                                    <MenuItem key={'red'} value={'RED'}><span style={{color: 'rgb(255, 72, 66)', marginRight:12}}>●</span></MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid  style={{width:'33%'}}>
                            <Typography variant="body2" style={{marginBottom: 10}}>
                                S/W(소프트웨어)
                            </Typography>
                            <FormControl  style={{marginBottom: 20, width:'33%'}}>
                                <InputLabel id="demo-simple-select-required-label">상태</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={SWVal}
                                    onChange={handleSWChange}
                                    label="Condition *">
                                    <MenuItem key={'green'} value={'GREEN'}><span style={{color: 'rgb(54, 200, 150)', marginRight:12}}>●</span></MenuItem>
                                    <MenuItem key={'yellow'} value={'YELLOW'}><span style={{color: 'rgb(255, 205, 86)', marginRight:12}}>●</span></MenuItem>
                                    <MenuItem key={'red'} value={'RED'}><span style={{color: 'rgb(255, 72, 66)', marginRight:12}}>●</span></MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid  style={{width:'33%'}}>
                            <Typography variant="body2" style={{marginBottom: 10}}>
                                OP(Operational)
                            </Typography>
                            <FormControl  style={{marginBottom: 20, width:'33%'}}>
                                <InputLabel id="demo-simple-select-required-label">상태</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={OPVal}
                                    onChange={handleOPChange}
                                    label="Condition *">
                                    <MenuItem key={'green'} value={'GREEN'}><span style={{color: 'rgb(54, 200, 150)', marginRight:12}}>●</span></MenuItem>
                                    <MenuItem key={'yellow'} value={'YELLOW'}><span style={{color: 'rgb(255, 205, 86)', marginRight:12}}>●</span></MenuItem>
                                    <MenuItem key={'red'} value={'RED'}><span style={{color: 'rgb(255, 72, 66)', marginRight:12}}>●</span></MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                        </Grid>
                        <Typography color="secondary" variant="subtitle1" gutterBottom>
                            방문일
                        </Typography>
                        <SDatePicker locale={ko} selected={visitDate} sx={{zIndex:'tooltip'}}  onChange={date => setVisitDate(date)} dateFormat="yyyy년 MM월 dd일" />
                        <Typography color="secondary" variant="subtitle1" gutterBottom>
                            담당자
                        </Typography>
                        <TextField onChange={handleManagerChange} value={manager} variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Typography color="secondary" variant="subtitle1" gutterBottom>
                            메모
                        </Typography>
                        <TextField onChange={handleMemoChange} value={memo} multiline rows={2} variant={"filled"}
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={handleClose} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={postSLF} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                등록
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

