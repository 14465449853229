import {useState} from 'react';
import {useNavigate} from "react-router-dom";
import base64 from 'base-64'
// @mui
import {alpha} from '@mui/material/styles';
import {Avatar, Box, Divider, IconButton, MenuItem, Popover, Typography} from '@mui/material';
import Swal from "sweetalert2";
// mocks_
import account from '../../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: 'eva:home-fill',
    },
    {
        label: 'Profile',
        icon: 'eva:person-fill',
    },
    {
        label: 'Settings',
        icon: 'eva:settings-2-fill',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const showAlert = () => {
        Swal.fire({
            title: "Logout",
            text: "로그아웃 하시겠습니까?",
            icon: "info",
            confirmButtonText: "확인",
            cancelButtonText: "취소"
        }).then((res) => {
            if(res.isConfirmed){
                localStorage.clear();
                navigate('/login', {replace: true});
            }
        });
    };

    const showLoginAlert = () => {
        Swal.fire({
            title: "Login",
            text: "로그인 후 이용 가능합니다.",
            icon: "info",
            confirmButtonText: "OK",

        }).then((res) => {
            navigate('/login')
        });
    };

    const showExpiredAlert = () => {
        Swal.fire({
            title: "로그인 시간 만료",
            text: "재로그인 후 이용 가능합니다.",
            icon: "info",
            confirmButtonText: "OK",

        }).then((res) => {
            localStorage.clear();
            navigate('/login')
        });
    };

    if(localStorage.getItem('accessToken')===null || localStorage.getItem('userId')===null){
        showLoginAlert();
    }

    if(localStorage.getItem('accessToken')!=null){
        const date = new Date();
        const token = localStorage.getItem("accessToken");
        let payload = null;
        let expiredDate = null;
        if(token!==null){
            payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            expiredDate = JSON.parse(base64.decode(payload)).exp;
            const convertDate = date.getTime().toString().substring(0,10);
            console.log(expiredDate)
            console.log(convertDate)
            console.log(expiredDate<convertDate)

            if(expiredDate< convertDate)
               showExpiredAlert();
        }
    }

    const handleLogout = () => {
        showAlert();
    };

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);

    };

    const token = localStorage.getItem("accessToken");
    let payload = null;
    let id = null;
    if(token!==null){
        payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
        id = JSON.parse(base64.decode(payload)).sub;
    }

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={account()} alt="photoURL"/>
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {id === null ? '':id}
                    </Typography>
                </Box>
                <Divider sx={{borderStyle: 'dashed'}}/>
                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
