import axios from 'axios';

// GET
export async function getSalesGoal(props) {
    const year = props.year;
    const month = props.month;

    try {
        return await axios.get('/api/sales-goal', {
            params: {
                year,
                month
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}


export async function getSLFs() {
    const page = 0;
    const size = 200;
    try {
        return await axios.get('/api/client/slf', {
            params: {
                page,
                size
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}

