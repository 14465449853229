import React from "react";

import {useMediaQuery} from 'react-responsive';
import {Helmet} from 'react-helmet-async';

// @mui
import {useTheme} from '@mui/material/styles';
import {Container, Divider, Grid, Typography} from '@mui/material';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js/auto";

import SLFs from "../customComponents/SLFs";
import SalesGoal from "../customComponents/SalesGoal";

ChartJS.register(ArcElement, Tooltip, Legend);
// components
// sections

// ----------------------------------------------------------------------

export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    return <>{isMobile && children}</>
}

export const PC = ({children}) => {
    const isPc = useMediaQuery({
        query: "(min-width:769px)"
    });

    return <>{isPc && children}</>
}

export default function DashboardAppPage() {

    const theme = useTheme();
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = (currentDate.getMonth() + 1).toString();

    return (
        <>
            <Helmet>
                <title> 대시보드 </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container>
                    <PC>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h4" sx={{mb: 5, textAlign: 'center'}}>
                                Today Is
                            </Typography>
                            <Typography variant="h2" sx={{mb: 5, textAlign: 'center'}}>
                                {year}년 {month.padStart(2, '0')}월 {currentDate.getDate().toString().padStart(2, '0')}일
                            </Typography>
                        </Grid>
                    </PC>
                    <Mobile>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h5" sx={{mb: 5, textAlign: 'center'}}>
                                Today Is
                            </Typography>
                            <Typography variant="h3" sx={{mb: 5, textAlign: 'center'}}>
                                {year}년 {month.padStart(2, '0')}월 {currentDate.getDate().toString().padStart(2, '0')}일
                            </Typography>
                        </Grid>
                    </Mobile>
                    <SalesGoal mVal={(currentDate.getMonth() + 1).toString()}/>
                    <Grid container justifyContent={'center'} spacing={2}>
                        <Divider sx={{my: 10}} variant="fullWidth">
                            <Typography variant="h3" sx={{color: 'text.secondary'}}>
                                SLF 사후 관리 현황
                            </Typography>
                        </Divider>
                    </Grid>
                    <Container sx={{mb: 6}} style={{display: 'flex'}}>
                        <PC>
                            <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                                <span style={{color: 'rgb(54, 200, 150)', marginRight: 12}}>●</span>정상동작 및 운영(활용도
                                양호) <br/>
                                <span style={{color: 'rgb(255, 205, 86)', marginRight: 12}}>●</span>정상동작 가능/성능저하 및
                                운영(활용도 보통) <br/>
                                <span style={{color: 'rgb(255, 72, 66)', marginRight: 12}}>●</span>정상동작 불가/성능저하 및 운영(활용도
                                불량) <br/>
                            </Typography>
                            <Typography variant="subtitle2" sx={{color: 'text.secondary'}} style={{marginLeft: 'auto'}}>
                                H/W : 하드웨어 <br/>
                                S/W : 소프트웨어 <br/>
                                OP : Operational <br/>
                            </Typography>
                        </PC>
                        <Mobile>
                            <Typography variant="body2" sx={{color: 'text.secondary'}} fontSize={'3vw'}  style={{marginRight:'2%'}}>
                                <span style={{color: 'rgb(54, 200, 150)', marginRight: 2}}>●</span>정상동작 및 운영<br/>(활용도
                                양호) <br/>
                                <span style={{color: 'rgb(255, 205, 86)', marginRight: 2}}>●</span>정상동작 가능/성능저하 및
                                운영<br/>(활용도 보통) <br/>
                                <span style={{color: 'rgb(255, 72, 66)', marginRight: 2}}>●</span>정상동작 불가/성능저하 및 운영<br/>(활용도
                                불량) <br/>
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}}
                                        style={{marginLeft:'auto'}} fontSize={'3vw'}>
                                H/W : <br/> 하드웨어 <br/>
                                S/W : <br/> 소프트웨어 <br/>
                                OP : <br/> Operational <br/>
                            </Typography>
                        </Mobile>
                    </Container>
                </Grid>
                <SLFs/>
            </Container>
        </>
    );
}