import axios from 'axios';

// GET
export async function getSlfs(props) {
    const page = props.page;
    const size = props.rowsPerPage;

    try {
        return await axios.get('/api/slf', {
            params: {
                page,
                size
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {        // 실패 시 처리
        console.error(e);
    }

    return null;
}




export async function addSLF(props) {
    const clientId=props.clientVal;
    const hwStatus=props.HWVal;
    const swStatus=props.SWVal;
    const opStatus=props.OPVal;
    const lastVisitedDate=props.visitDate;
    const manager=props.manager;
    const memo=props.memo;

    try {




        return await axios.post('/api/slf', {
                clientId,
                hwStatus,
                swStatus,
                opStatus,
                manager,
                lastVisitedDate,
                memo
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
                },
            });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}

