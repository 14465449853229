import React, {useEffect, useState} from 'react';
import base64 from "base-64";
import {Helmet} from 'react-helmet-async';
import {useMediaQuery} from "react-responsive";
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {styled} from "@mui/material/styles";
// components
// sections
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';

// mock
import SalesGoal from "../customComponents/SalesGoal";
import {addSales, getSales, setGoalSetting} from "../axios/SalesGoal";


// ----------------------------------------------------------------------


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

// ----------------------------------------------------------------------


const currentDate = new Date();


export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    return <>{isMobile && children}</>
}

export const PC = ({children}) => {
    const isPc = useMediaQuery({
        query: "(min-width:769px)"
    });

    return <>{isPc && children}</>
}
export default function SalesGoalPage() {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [month,setMonth] = useState(currentDate.getMonth() + 1);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const [contents, setContents] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openGoalPopup, setOpenGoalPopup] = React.useState(false);
    const [openYearGoalPopup, setOpenYearGoalPopup] = React.useState(false);
    const [openMonthGoalPopup, setOpenMonthGoalPopup] = React.useState(false);
    const [amount, setAmount] = useState('');

    const [yearGoalAmount,setYearGoalAmount] = useState('');
    const [monthGoalAmount, setMonthGoalAmount] = useState('');

    const currentYear = currentDate.getFullYear();
    const yearList = [currentYear,currentYear+1, currentYear+2];
    const monthList = [1,2,3,4,5,6,7,8,9,10,11,12];
    const [goalType, setGoalType] = useState('');
    const modifiedReason = "";
    const [totalRecordCount,setTotalRecordCount] = useState(0);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const yearGoalPopup = () => {
        setOpenYearGoalPopup(true);
        setOpenGoalPopup(false);
    };

    const closeYearGoalPopup = () => {
        setOpenYearGoalPopup(false);
    };

    const monthGoalPopup = () => {
        setOpenMonthGoalPopup(true);
        setOpenGoalPopup(false);
    };

    const closeMonthGoalPopup = () => {
        setOpenMonthGoalPopup(false);
    };

    const settingGoalHandleOpen = () => {
        setOpenGoalPopup(true);
    };
    const settingGoalHandleClose = () => {
        setOpenGoalPopup(false);
    };
    const setSalesAmount = (e) => {
        setAmount(e.target.value);
    };

    const setYearAmount = (e) => {
        setYearGoalAmount(e.target.value);
    };
    const setMonthAmount = (e) => {
        setMonthGoalAmount(e.target.value);
    };

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#3F51B5',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        async function fetchData() {
            try {
                const sales = await getSales({page, rowsPerPage, month});
                setContents(sales.data.content);
                setTotalRecordCount(sales.data.totalRecordCount);
            } catch (error) {
                console.log(error); // 비동기 함수의 결과 처리
            }
        }
        fetchData();
    }, [month,rowsPerPage,page]);


    const postSalesAmount = async () => {
        await addSales({amount, month, year})
            .then(() => {
                setOpen(false);
                window.location.reload();
            });
    }

    const postGoalAmount = () => {
        setGoalSetting({monthGoalAmount,yearGoalAmount, month, year, goalType,modifiedReason});
    }

    const handleChange = (event: SelectChangeEvent) => {
        setMonth(event.target.value);
    };

    const [year, setYear] = React.useState(currentDate.getFullYear());
    const handleYearChange = (event: SelectChangeEvent) => {
        setYear(event.target.value);
    };


    const token = localStorage.getItem("accessToken");
    let payload = null;
    let auth = null;
    if(token!==null){
        payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
        auth = JSON.parse(base64.decode(payload)).auth;
    }
    const buttonYN = () =>{

        if(auth === 1 || auth === 2){
            return (
                <PC>
                    <Grid>
                        <Button onClick={handleOpen} color="secondary" variant="contained" style={{width:'100px'}}>
                            매출 등록
                        </Button>
                    </Grid>
                </PC>
            )
        }
        return null;
    }



    return (
        <>
            <Helmet>
                <title> 매출 현황 조회 </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        매출 현황 조회
                    </Typography>
                    {buttonYN()}
                </Stack>
                <PC>
                    <FormControl sx={{m: 1, width:'20%', float: 'right'}} style={{backgroundColor:"white"}}>
                        <InputLabel id="demo-simple-select-required-label">날짜</InputLabel>
                        <Select
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={month}
                            label="월 *"
                            onChange={handleChange}>
                            {monthList.map((m) => (
                                <MenuItem value={m}>{m.toString().concat('월')}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </PC>
                <Mobile>
                    <FormControl sx={{m: 1, width:'40%', float: 'right'}}>
                        <InputLabel id="demo-simple-select-required-label">날짜</InputLabel>
                        <Select
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={month}
                            label="월 *"
                            onChange={handleChange}>
                            {monthList.map((m) => (
                                <MenuItem value={m}>{m.toString().concat('월')}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Mobile>
                <Grid style={{marginBottom: 40}}>
                    <SalesGoal mVal={month}/>
                </Grid>

                <Card>

                    <TableContainer style={{whiteSpace:'nowrap'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">ID</StyledTableCell>
                                    <StyledTableCell align="center">매출액</StyledTableCell>
                                    <StyledTableCell align="center">등록일</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contents.map((content) => (
                                    <StyledTableRow key={content.id}>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {content.id}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center">{(content.amount / 100000000).toString().concat("억")}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{(content.createdDate).toString().slice(0, 10)}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={totalRecordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <h2 id="parent-modal-title" style={{marginBottom: 30}}>{month}월 매출 등록</h2>
                        <TextField onChange={setSalesAmount} value={amount} label="매출액" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={handleClose} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={postSalesAmount} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                등록
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

