import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import base64 from "base-64";
import {useMediaQuery} from "react-responsive";
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {styled} from "@mui/material/styles";
// components
// sections

// mock
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {addArea, getClients, getAreas, addClient, putClient} from "../axios/Client";


// ----------------------------------------------------------------------


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

// ----------------------------------------------------------------------


export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    return <>{isMobile && children}</>
}

export const PC = ({children}) => {
    const isPc = useMediaQuery({
        query: "(min-width:769px)"
    });

    return <>{isPc && children}</>
}


export default function ClientPage() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = React.useState(false);
    const [openClient, setOpenClient] = React.useState(false);
    const [openUpdateClient, setOpenUpdateClient] = React.useState(false);
    const [area, setArea] = React.useState('')
    const [client, setClient] = React.useState('');
    const [manager, setManager] = React.useState('');
    const [areaList, setAreaList] = React.useState([]);
    const [totalRecordCount,setTotalRecordCount] = useState(0);
    const [id, setId] = React.useState(0);

    const [updateClient, setUpdateClient] = React.useState('');
    const [updateArea, setUpdateArea] = React.useState('');
    const [updateManager, setUpdateManager] = React.useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenClient = () => {
        setOpenClient(true);
    };
    const handleCloseClient = () => {
        setOpenClient(false);
    };

    const handleOpenUpdateClient = () => {
        setOpenUpdateClient(true);
    };
    const handleCloseUpdateClient = () => {
        setOpenUpdateClient(false);
    };
    const handleUpdateManagerChange = (e) =>{
        setUpdateManager(e.target.value);
    }

    const setAreaName = (e) => {
        setArea(e.target.value);
    }

    const setClientName = (e) => {
        setClient(e.target.value);
    }

    const setManagerName = (e) => {
        setManager(e.target.value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const [contents, setContents] = useState([]);

    const [areaVal, setAreaVal] = React.useState('');
    const handleAreaChange = (event: SelectChangeEvent) => {
        setAreaVal(event.target.value);
    };

    const handleUpdateAreaChange = (event: SelectChangeEvent) => {
        setUpdateArea(event.target.value);
    };

    const handleUpdateClientChange = (event: SelectChangeEvent) => {
        setUpdateClient(event.target.value);
    };

    const clickItem = (value) => {

        if(auth ===1){
            setId(value.id);
            setUpdateClient(value.name);
            setUpdateManager(value.manager);
            setUpdateArea(value.area.id);
            handleOpenUpdateClient();
        }
    }

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#3F51B5',
            color: theme.palette.common.white,
            border: '1px solid white',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));


    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const postArea = async () => {
        await addArea({area}).then(
            (res) => {
                if (res != null) {
                    handleClose();
                }
            });
    }

    const postClient = async () => {
        await addClient({areaVal,manager,client}).then(
            (res) => {
                if (res != null) {
                    handleClose();
                    window.location.reload();
                }
            });
    }

    const updateClientInfo = async () => {
        await putClient({updateArea,updateManager,updateClient,id}).then(
            (res) => {
                if (res != null) {
                    handleClose();
                    window.location.reload();
                }
            });
    }

    const colorDot = (color) => {
        if(color==="GREEN"){
            return (
                <span style={{color: 'rgb(54, 200, 150)', marginRight:12}}>●</span>
            )
        }
        if(color==="YELLOW"){
            return (
                <span style={{color: 'rgb(255, 205, 86)', marginRight:12}}>●</span>
            )
        }
        if(color==="RED"){
            return (
                <span style={{color: 'rgb(255, 72, 66)', marginRight:12}}>●</span>
            )
        }
        return (
            <span style={{marginRight:12}}>-</span>
        )

    };

    const lastVisitedDate = (lastVisitedDate) => {
        if(lastVisitedDate!==null){
            return (
                <span>{(lastVisitedDate).toString().slice(0, 10)}</span>
            )
        }
        return (
            <span>-</span>
        )

    };

    const passOverDate = (lastVisitedDate) => {
        if(lastVisitedDate!==null){
            return (
                <span>D + { Math.ceil(Math.abs(currentDate - (new Date((lastVisitedDate).toString().slice(0,10))))/(1000 * 60 * 60 * 24)) }</span>
            )
        }
        return (
            <span>-</span>
        )

    };

    useEffect(() => {
        async function fetchData() {
            try {
                const clients = await getClients({page, rowsPerPage});
                const areas = await getAreas();
                setAreaList(areas.data);
                setContents(clients.data.content);
                setTotalRecordCount(clients.data.totalElements);
            } catch (error) {
                console.log(error); // 비동기 함수의 결과 처리
            }
        }

        fetchData();
    }, [rowsPerPage,page]);
    const currentDate = new Date();

    const token = localStorage.getItem("accessToken");
    let payload = null;
    let auth = null;
    if(token!==null){
        payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
        auth = JSON.parse(base64.decode(payload)).auth;
    }
    const buttonYN = () =>{

        if(auth === 1 || auth === 2){
            return (
                <PC>
                    <Grid>
                        <Button onClick={handleOpen} color="warning" variant="contained">
                            지역 등록
                        </Button>
                        <Button onClick={handleOpenClient} color="secondary" variant="contained" style={{marginLeft: 20}}>
                            고객사 등록
                        </Button>
                    </Grid>
                </PC>
            )
        }
        return null;
    }



    return (
        <>
            <Helmet>
                <title> 고객 관리 </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        고객 관리
                    </Typography>
                    {buttonYN()}
                </Stack>

                <Card>
                    <TableContainer style={{whiteSpace:'nowrap'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" rowSpan={2}>ID</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>고객사</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>지역</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>담당자</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={3}>Last Updated Condition</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>방문일</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>경과일</StyledTableCell>
                                    <StyledTableCell align="center" rowSpan={2}>등록일</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="center">H/W</StyledTableCell>
                                    <StyledTableCell align="center">S/W</StyledTableCell>
                                    <StyledTableCell align="center">OP</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contents.map((content) => (
                                    <StyledTableRow key={content.id} onClick={()=>clickItem(content)}>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {content.id}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center">{content.name}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{content.area.name}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{content.manager}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{colorDot(content.hwStatus)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{colorDot(content.swStatus)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{colorDot(content.opStatus)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{lastVisitedDate(content.lastVisitedDate)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{passOverDate(content.lastVisitedDate)}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{(content.createdDate).toString().slice(0, 10)}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={totalRecordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <h2 id="parent-modal-title" style={{marginBottom: 30}}>지역 등록</h2>
                        <TextField onChange={setAreaName} value={area} label="지역 명" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={handleClose} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={postArea} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                등록
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={openClient}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <h2 id="parent-modal-title" style={{marginBottom: 30}}>고객사 등록</h2>
                        <TextField onChange={setClientName} value={client} label="고객사 명" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <FormControl sx={{width: '100%'}} style={{marginBottom: 30}}>
                            <InputLabel id="demo-simple-select-required-label">지역</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={areaVal}
                                label="지역 *"
                                onChange={handleAreaChange}>
                                {areaList.map((area) => (
                                    <MenuItem key={area.id} value={area.id}>{area.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField onChange={setManagerName} value={manager} label="담당자" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={handleCloseClient} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={postClient} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                등록
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={openUpdateClient}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <h2 id="parent-modal-title" style={{marginBottom: 30}}>고객사 수정</h2>
                        <TextField onChange={handleUpdateClientChange} value={updateClient} label={updateClient} variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <FormControl sx={{width: '100%'}} style={{marginBottom: 30}}>
                            <InputLabel id="demo-simple-select-required-label">지역</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={updateArea}
                                label="지역 *"
                                onChange={handleUpdateAreaChange}>
                                {areaList.map((area) => (
                                    <MenuItem key={area.id} value={area.id} selected={area.id===updateArea}>{area.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField onChange={handleUpdateManagerChange} value={updateManager} label="담당자" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={handleCloseUpdateClient} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={updateClientInfo} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                수정
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>


        </>
    )
}

