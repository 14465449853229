// component
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    id:'1',
    title: '대시보드',
    path: '/dashboard',
    icon: icon('ic_analytics'),
    children:[]
  },
  {
    id:'2',
    title: '매출 현황 관리',
    path: '/sales-goal',
    icon: icon('ic_user'),
    children:[
      {
        id:'2-1',
        title: '매출 현황 조회',
        path: '/sales-goal',
        icon: icon('ic_cart'),
        children:[]
      },
      {
        id:'2-2',
        title: '매출 목표 조회',
        path: '/sales-goal-history',
        icon: icon('ic_analytics'),
        children:[]
      }
    ]
  },
  {
    id:'3',
    title: 'SLF/고객 관리',
    path: '/client',
    icon: icon('ic_user'),
    children:[
      {
        id:'3-1',
        title: '고객 관리',
        path: '/client',
        icon: icon('ic_analytics'),
      },
      {
        id:'3-2',
        title: 'SLF 관리',
        path: '/slf-managing',
        icon: icon('ic_analytics'),
      }
    ]
  }
];

export default navConfig;
