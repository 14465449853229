import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react"
// @mui
import {Container, Grid, Typography} from '@mui/material';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js/auto";
import {Doughnut} from "react-chartjs-2";
import ScrollContainer from "react-indiana-drag-scroll";
import Context from "chartjs-plugin-datalabels"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import {getSLFs} from '../axios/Dashboard'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'


ChartJS.register(ArcElement, Tooltip, Legend, Context);

const GREEN = 'rgb(54, 200, 150)';
const YELLOW = 'rgb(255, 205, 86)';
const RED = 'rgb(255, 72, 66)';
export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    return <>{isMobile && children}</>
}

export const PC = ({children}) => {
    const isPc = useMediaQuery({
        query: "(min-width:769px)"
    });

    return <>{isPc && children}</>
}
export default function SLFs() {

    const options = {
        plugins: {
            tooltip:{
              enabled:false
            },
            datalabels: {
                display: true,
                formatter: (val, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return `${label}`;
                },
                color: '#000',
                font: {
                    size: 14,
                },
            },
            legend: {
                display: false,
            },
        },
    };

    const currentDate = new Date();

    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const slfs = await getSLFs();
                setData(slfs.data.content);
            } catch (error) {
                console.log(error); // 비동기 함수의 결과 처리
            }
        }

        fetchData();
    }, []);

    return (
        <>
            <PC>
                <Container style={{display: 'flex'}} sx={{alignItems: "center"}}>
                    <ScrollContainer style={{display:'flex'}}>
                        {data.filter(ele => ele.hwStatus!=null).map((content) => {
                                return (
                                    <Grid item xs={12} md={6} lg={4} sx={{textAlign: 'center'}}>
                                        <Doughnut data={
                                            {
                                                labels: ['HW', 'SW', 'OP'],
                                                datasets: [{
                                                    data: [1, 1, 1],
                                                    backgroundColor: [backgroundCode(content.hwStatus), backgroundCode(content.swStatus), backgroundCode(content.opStatus)],
                                                }],
                                            }} options={options}/>
                                        <Typography variant="h5" sx={{color: 'text.secondary', marginTop: 2}}>
                                            {content.name}
                                        </Typography>
                                        <Container style={{display: 'flex'}}>
                                            <Typography variant="inherit" sx={{color: 'text.secondary'}}>
                                                {(content.lastVisitedDate).toString().slice(0, 10)}
                                            </Typography>
                                            <Typography variant="inherit"
                                                        sx={{color: 'text.secondary', marginLeft: 'auto'}}>
                                                D +
                                                {Math.ceil(Math.abs(currentDate - (new Date((content.lastVisitedDate).toString().slice(0, 10)))) / (1000 * 60 * 60 * 24))}
                                            </Typography>
                                        </Container>
                                    </Grid>
                                )
                        })}
                    </ScrollContainer>
                </Container>
            </PC>
            <Mobile>
                <Container sx={{alignItems: "center"}}>
                    <Swiper slidesPerView={1} >
                        {data.filter(ele => ele.hwStatus!=null).map((content) => {
                               return (
                                    <SwiperSlide>
                                        <Grid item xs={6} md={2} lg={2} sx={{textAlign: 'center'}} >
                                            <Doughnut data={
                                                {
                                                    labels: ['HW', 'SW', 'OP'],
                                                    datasets: [{
                                                        data: [1, 1, 1],
                                                        backgroundColor: [backgroundCode(content.hwStatus), backgroundCode(content.swStatus), backgroundCode(content.opStatus)],
                                                    }],
                                                }} options={options}/>
                                            <Typography variant="subtitle2" sx={{color: 'text.secondary', marginTop: 2}}>
                                                {content.name}
                                            </Typography>
                                            <Container style={{display: 'flex'}}>
                                                <Typography variant="body1" sx={{color: 'text.secondary'}}>
                                                    {(content.createdDate).toString().slice(0, 10)}
                                                </Typography>
                                                <Typography variant="body2" sx={{color: 'text.secondary', marginLeft: 'auto'}}>
                                                    D +
                                                    {Math.ceil(Math.abs(currentDate - (new Date((content.createdDate).toString().slice(0, 10)))) / (1000 * 60 * 60 * 24))}
                                                </Typography>
                                            </Container>
                                        </Grid>
                                    </SwiperSlide>
                            )
                        })}
                    </Swiper>

                </Container>
            </Mobile>
        </>


    )
}

function backgroundCode(color) {
    switch (color) {
        case "GREEN" : {
            return GREEN;
        }
        case "YELLOW" : {
            return YELLOW;
        }
        case "RED" : {
            return RED;
        }
        default: {
            return GREEN;
        }
    }
}