import axios from 'axios';

// GET
export async function getSales(props) {
    const month = props.month;
    const page = props.page;
    const size = props.rowsPerPage;


    try {
        return await axios.get('/api/sales', {
            params: {
                page,
                size,
                month
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"

            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}


export async function addSales(props) {
    const month = props.month;
    const amount = props.amount;
    const year = props.year;
    try {
        return await axios.post('/api/sales',{
                month,
                amount,
                year
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}


export async function setGoalSetting(props) {
    const goalType = props.goalType;
    let amount = 0;
    let month = 0;
    if(goalType==="YEAR"){
        amount = props.yearGoalAmount;

    }else{
        amount = props.monthGoalAmount;
        month = props.month;
    }
    const year = props.year;
    const modifiedReason=props.modifiedReason;
    try {
        return await axios.post('/api/sales-goal',{
                goalType,
                modifiedReason,
                month,
                amount,
                year
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
                },
            });
    } catch (e) {
        // 실패
        console.error(e);
    }

    return null;
}


export async function getSalesGoalHistory(props) {
    const page = props.page;
    const size = props.rowsPerPage;
    const month = props.month;
    const year = props.year;

    console.log(page,size,month);

    try {
        return await axios.get('/api/sales-goal/history', {
            params: {
                page,
                size,
                month,
                year
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}