import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import base64 from "base-64";
import {useMediaQuery} from "react-responsive";
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Modal,
    Stack,
    TableCell,
    tableCellClasses,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {styled} from "@mui/material/styles";
// components
// sections
// mock
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import '../customCSS/common.css'
import {setGoalSetting} from "../axios/SalesGoal";
import {getSalesGoal} from "../axios/Dashboard";

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

// ----------------------------------------------------------------------


export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    return <>{isMobile && children}</>
}

export const PC = ({children}) => {
    const isPc = useMediaQuery({
        query: "(min-width:769px)"
    });

    return <>{isPc && children}</>
}


export default function SalesGoalHistoryPage() {

    const currentDate = new Date();
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [yearSalesGoal, setYearSalesGoal] = useState(0);
    const [monthSalesGoal, setMonthSalesGoal] = useState(0);
    const currentYear = currentDate.getFullYear();
    const yearList = [currentYear, currentYear + 1, currentYear + 2];
    const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const [monthSalesGoalParse1, setMonthSalesGoalParse1] = useState("");
    const [monthSalesGoalParse2, setMonthSalesGoalParse2] = useState("");

    const [openGoalPopup, setOpenGoalPopup] = React.useState(false);
    const [openYearGoalPopup, setOpenYearGoalPopup] = React.useState(false);
    const [openMonthGoalPopup, setOpenMonthGoalPopup] = React.useState(false);
    const [yearGoalAmount, setYearGoalAmount] = useState('');
    const [monthGoalAmount, setMonthGoalAmount] = useState('');
    const [goalType, setGoalType] = useState('');


    const yearGoalPopup = () => {
        setOpenYearGoalPopup(true);
        setOpenGoalPopup(false);
    };

    const closeYearGoalPopup = () => {
        setOpenYearGoalPopup(false);
    };

    const monthGoalPopup = () => {
        setOpenMonthGoalPopup(true);
        setOpenGoalPopup(false);
    };

    const closeMonthGoalPopup = () => {
        setOpenMonthGoalPopup(false);
    };

    const settingGoalHandleOpen = () => {
        setOpenGoalPopup(true);
    };
    const settingGoalHandleClose = () => {
        setOpenGoalPopup(false);
    };

    const [contents, setContents] = useState([]);

    const handleChange = (event: SelectChangeEvent) => {
        setMonth(event.target.value);
    };

    const postGoalAmount = () => {
        setGoalSetting({monthGoalAmount, yearGoalAmount, month, year, goalType, modifiedReason})
            .then((res) => {
                if (res != null) {
                    window.location.reload();
                }
            });
    }

    const [year, setYear] = React.useState(currentDate.getFullYear());
    const handleYearChange = (event: SelectChangeEvent) => {
        setYear(event.target.value);
    };

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#3F51B5',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const setYearAmount = (e) => {
        setYearGoalAmount(e.target.value);
    };
    const setMonthAmount = (e) => {
        setMonthGoalAmount(e.target.value);
    };
    const [modifiedReason, setModifiedReason] = React.useState('');
    const handleModifiedReasonChange = (event: SelectChangeEvent) => {
        setModifiedReason(event.target.value);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const salesGoalData = await getSalesGoal({month, year}); // 비동기 함수 호출
                setYearSalesGoal(salesGoalData.data.yearSalesGoal);

                setMonthSalesGoal(salesGoalData.data.monthSalesGoal);
                setMonthSalesGoalParse1(Number(salesGoalData.data.monthSalesGoal/10000000).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

                console.log(contents);
            } catch (error) {
                console.log(error); // 비동기 함수의 결과 처리
            }
        }

        fetchData();
    }, [month, year]);


    const token = localStorage.getItem("accessToken");
    let payload = null;
    let auth = null;
    if (token !== null) {
        payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
        auth = JSON.parse(base64.decode(payload)).auth;
    }
    const buttonYN = () => {

        if (auth === 1) {
            return (
                <PC>
                    <Grid>
                        <Button onClick={settingGoalHandleOpen} color="secondary" variant="contained"
                                style={{marginLeft: 20}}>
                            매출 목표 변경
                        </Button>
                    </Grid>
                </PC>
            )
        }
        return null;
    }

    return (
        <>
            <Helmet>
                <title> 매출 목표 </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        매출 목표
                    </Typography>
                    {buttonYN()}
                </Stack>
                <Card>
                    <PC>
                        <FormControl sx={{m: 1, width: '20%', float: 'right'}}>
                            <InputLabel id="demo-simple-select-required-label">월</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={month}
                                label="월 *"
                                onChange={handleChange}>
                                {monthList.map((m) => (
                                    <MenuItem value={m}>{m.toString().concat('월')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{m: 1, width: '20%', float: 'right'}}>
                            <InputLabel id="demo-simple-select-required-label">년도</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={year}
                                label="년도 *"
                                onChange={handleYearChange}>
                                {yearList.map((y) => (
                                    <MenuItem value={y}>{y.toString().concat('년')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </PC>
                    <Mobile>
                        <FormControl sx={{marginTop: 1, marginLeft: 1, width: '50%'}}>
                            <InputLabel id="demo-simple-select-required-label">년도</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={year}
                                label="년도 *"
                                onChange={handleYearChange}>
                                {yearList.map((y) => (
                                    <MenuItem value={y}>{y.toString().concat('년')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{m: 1, width: '40%'}}>
                            <InputLabel id="demo-simple-select-required-label">월</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={month}
                                label="월 *"
                                onChange={handleChange}>
                                {monthList.map((m) => (
                                    <MenuItem value={m}>{m.toString().concat('월')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Mobile>
                    <PC>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  marginTop={15} marginBottom={10}>
                            <Grid xs={6} textAlign={"center"}>
                                <Typography variant="h4" mb={2} marginRight={2}>
                                    * {year}년 매출 목표
                                </Typography>
                                <Typography variant="h3" color={"error"} mb={2} marginRight={2}>
                                    {(yearSalesGoal / 100000000).toString().concat("억원")}
                                </Typography>
                            </Grid>
                            <Grid xs={6} textAlign={"center"}>
                                <Typography variant="h4" mb={2} marginRight={1}>
                                    * {month}월 매출 목표
                                </Typography>
                                <Typography variant="h3" color={"error"} mb={2} marginRight={2}>
                                    {monthSalesGoalParse1.concat("천만원")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </PC>
                    <Mobile>
                        <Grid container rowSpacing={1} marginTop={4} marginBottom={2}>
                            <Grid xs={6} textAlign={"center"}>
                                <Typography variant="subtitle1" mb={2}>
                                    * {year}년 <br/> 매출 목표
                                </Typography>
                                <Typography variant="subtitle1" color={"error"} mb={2}>
                                    {(yearSalesGoal / 100000000).toString().concat("억원")}
                                </Typography>
                            </Grid>
                            <Grid xs={6} textAlign={"center"}>
                                <Typography variant="subtitle1" mb={2} >
                                    * {month}월 <br/> 매출 목표
                                </Typography>
                                <Typography variant="subtitle1" color={"error"} mb={2}>
                                    {monthSalesGoalParse1.concat("천만원")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Mobile>
                </Card>



            </Container>
            <div>
                <Modal
                    open={openGoalPopup}
                    aria-labelledby="setting-goal-modal-title"
                    aria-describedby="setting-goal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <Container style={{display: 'flex'}}>
                            <h2 id="setting-goal-modal-title" style={{marginBottom: 30}}>매출 목표 변경</h2>
                            <Button onClick={settingGoalHandleClose} color="inherit" variant="outlined"
                                    style={{marginTop: 10, marginLeft: 'auto', width: '80px', height: '40px'}}>
                                닫기
                            </Button>
                        </Container>
                        <Grid style={{textAlign: 'center', marginBottom: 30}}>
                            <Button onClick={yearGoalPopup} variant="contained" style={{
                                marginRight: 20,
                                width: '40%',
                                fontSize: '16px',
                            }}
                                    color={"secondary"}>
                                연간 목표 <br/>
                                매출액 변경
                            </Button>
                            <Button onClick={monthGoalPopup} variant="contained"
                                    style={{width: '40%', fontSize: '16px', color: '#fff', backgroundColor: "#893bff"}}>
                                월별 누적 목표 <br/>
                                매출액 변경
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={openYearGoalPopup}
                    aria-labelledby="year-goal-modal-title"
                    aria-describedby="year-goal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <h2 id="year-goal-modal-title" style={{marginBottom: 30}}>연간 매출 목표 변경</h2>
                        <Grid style={{display: 'flex'}} mb={2}>
                            <Typography variant="subtitle2" gutterBottom mr={2}>
                                변경 전 매출 목표액 :
                            </Typography>
                            <Typography variant="subtitle2" color={"error"} gutterBottom>
                                {(yearSalesGoal / 100000000).toString().concat("억원")}
                            </Typography>
                        </Grid>
                        <FormControl sx={{width: '100%'}} style={{marginBottom: 30}}>
                            <InputLabel id="demo-simple-select-required-label">연도</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={year}
                                label="년도 *"
                                onChange={handleYearChange}>
                                {yearList.map((y) => (
                                    <MenuItem value={y}>{y.toString().concat('년')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField onChange={setYearAmount} value={yearGoalAmount} label="목표 매출액" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={closeYearGoalPopup} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={() => {
                                setGoalType("YEAR");
                                postGoalAmount();
                            }} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                등록
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={openMonthGoalPopup}
                    aria-labelledby="month-goal-modal-title"
                    aria-describedby="month-goal-description">
                    <Box sx={{...style, width: '30%'}}>
                        <h2 id="month-goal-modal-title" style={{marginBottom: 30}}>월간 매출 목표</h2>
                        <Grid style={{display: 'flex'}} mb={2}>
                            <Typography variant="subtitle2" gutterBottom mr={2}>
                                변경 전 매출 목표액 :
                            </Typography>
                            <Typography variant="subtitle2" color={"error"} gutterBottom>
                                {(monthSalesGoal / 100000000).toString().concat("억원")}
                            </Typography>
                        </Grid>
                        <FormControl sx={{width: '100%'}} style={{marginBottom: 30}}>
                            <InputLabel id="year-simple-select-required-label">년도</InputLabel>
                            <Select
                                labelId="year-simple-select-required-label"
                                id="year-simple-select-required"
                                value={year}
                                label="년도 *">
                                <MenuItem value={year}>{year.toString().concat('년도')}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{width: '100%'}} style={{marginBottom: 30}}>
                            <InputLabel id="demo-simple-select-required-label">월</InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={month}
                                label="월 *">
                                <MenuItem value={month}>{month.toString().concat('월')}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField onChange={setMonthAmount} value={monthGoalAmount} label="목표 매출액" variant="outlined"
                                   style={{width: "100%", marginBottom: 30}}/>
                        <Grid style={{display: 'flex', float: 'right'}}>
                            <Button onClick={closeMonthGoalPopup} color="inherit" variant="contained"
                                    style={{marginRight: 20, width: '100px'}}>
                                취소
                            </Button>
                            <Button onClick={() => {
                                setGoalType("MONTH");
                                postGoalAmount();
                            }} color="secondary" variant="contained"
                                    style={{width: '100px'}}>
                                등록
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

