import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import SalesGoalPage from './pages/SalesGoalPage';
import SalesGoalHistoryPage from './pages/SalesGoalHistoryPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import ClientPage from './pages/ClientPage'
import SLFManagingPage from './pages/SLFManagingPage'

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '/dashboard',
            element: <DashboardLayout/>,
            children: [
                {path: '', element: <DashboardAppPage/>},
            ],
        },
        {
            path: 'sales-goal',
            element: <DashboardLayout/>,
            children: [
                {path: '', element: <SalesGoalPage/>}
            ]
        },
        {
            path: 'sales-goal-history',
            element: <DashboardLayout/>,
            children: [
                {path: '', element: <SalesGoalHistoryPage/>}
            ]
        },
        {
            path: 'client',
            element: <DashboardLayout/>,
            children: [
                {path: '', element: <ClientPage/>}
            ]
        },
        {
            path: 'slf-managing',
            element: <DashboardLayout/>,
            children: [
                {path: '', element: <SLFManagingPage/>}
            ]
        },
        {
            path: 'login',
            element: <LoginPage/>,
        },
        {
            element: <SimpleLayout/>,
            children: [
                {element: <Navigate to="/dashboard"/>, index: true},
                {path: '404', element: <Page404/>},
                {path: '*', element: <Navigate to="/404"/>},
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace/>,
        },
    ]);

    return routes;
}
