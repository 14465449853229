import axios from 'axios';

// GET
export async function getClients(props) {
    const page = props.page;
    const size = props.rowsPerPage;

    console.log(page,size);

    try {
        return await axios.get('/api/client', {
            params: {
                page,
                size
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}

export async function addClient(props) {
    const name = props.client;
    const areaId = props.areaVal;
    const manager = props.manager;

    try {
        return await axios.post('/api/client', {
                name,
                areaId,
                manager
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
                },
            });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}


export async function putClient(props) {
    const name = props.updateClient;
    const id = props.id;
    const areaId = props.updateArea;
    const manager = props.updateManager;

    try {
        return await axios.put("/api/client/".concat(id), {
                name,
                areaId,
                manager
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
                },
            });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}


export async function addArea(props) {
    const name = props.area;
    try {
        return await axios.post('/api/area', {
            name
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}


export async function getAreas() {

    try {
        return await axios.get('/api/area', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '.concat(localStorage.getItem("accessToken")),
                'Access-Control-Allow-Origin' : "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
            },
        });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}