import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {useTheme} from '@mui/material/styles';
import {Box, Link} from '@mui/material';
import iegLogo from './ieg_kor_horizontal.png'
// ----------------------------------------------------------------------

const EnglishLogo = forwardRef(({disabledLink = false, sx, ...other}, ref) => {
    const theme = useTheme();

    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                height: 'auto',
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        >
            <img src={iegLogo} alt={"ieg"}/>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return (
        <Link to="/" component={RouterLink} sx={{display: 'contents'}}>
            {logo}
        </Link>
    );
});

EnglishLogo.propTypes = {
    sx: PropTypes.object,
    disabledLink: PropTypes.bool,
};

export default EnglishLogo;
