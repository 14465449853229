import axios from 'axios';

export async function login(props) {
    const id = props.id;
    const password = props.password;
    try {
        return await axios.post('/api/user', {
                id,
                password
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin' : "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
                },
            });
    } catch (e) {
        // 실패 시 처리
        console.error(e);
    }

    return null;
}
