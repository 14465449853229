// ----------------------------------------------------------------------





import base64 from "base-64";
import {Button, Grid} from "@mui/material";
import React from "react";
import {PC} from "../pages/SLFManagingPage";

const account = () => {

  const token = localStorage.getItem("accessToken");
  let payload = null;
  let auth = null;
  if(token!==null){
    payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
    auth = JSON.parse(base64.decode(payload)).auth;

    if(auth === 1){
      return '/favicon/master.png';
    }
    return '/favicon/admin.png';
  }
  return null;
}

export default account;
