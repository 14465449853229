import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from "sweetalert2";
// components
import Iconify from '../../../components/iconify';
import {login} from "../../../axios/Login";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const onIdHandler = (event) => {
        setId(event.currentTarget.value);
    }
    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value);
    }
    const onSubmitHandler = (event) => {
        event.preventDefault();
    }

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
      login({id, password}).then((res)=>{

          if(res.data.accessToken != null ){
              localStorage.setItem('accessToken', res.data.accessToken);
              localStorage.setItem('refreshToken', res.data.refreshToken);
              localStorage.setItem('userId', res.data.userId);
              navigate('/dashboard', { replace: true });
          }else{
              Swal.fire({
                  title: "Login Fail",
                  text: "로그인에 실패 했습니다. 다시 시도해 주세요.",
                  icon: "error",
                  confirmButtonText: "OK",
              });
          }
      })
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="id"
                   value={id}
                   onChange={onIdHandler}
                   label="id" />
        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={onPasswordHandler}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton onSubmit={onSubmitHandler} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              Login
        </LoadingButton>
      </Stack>
    </>
  );
}
