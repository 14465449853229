import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {AppCurrentVisits} from "../sections/@dashboard/app";
import {getSalesGoal} from "../axios/Dashboard";

export default function SalesGoal({mVal}) {

    const theme = useTheme();
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = mVal;
    const [yearGoalContrast, setYearGoalContrast] = useState("");
    const [monthGoalContrast, setMonthGoalContrast] = useState("");
    const [remainMonthSalesGoal, setRemainMonthSalesGoal] = useState("");
    const [remainYearSalesGoal, setRemainYearSalesGoal] = useState("");
    const [yearSalesGoal, setYearSalesGoal] = useState("");
    const [monthSalesGoal, setMonthSalesGoal] = useState("");
    const [yearSales, setYearSales] = useState("");
    const [monthSales, setMonthSales] = useState("");
    const [yearSalesParse1,setYearSaleParse1] = useState("");
    const [monthSalesGoalParse1,setMonthSalesGoalParse1] = useState("");
    const [monthSalesParse1,setMonthSalesParse1] = useState("");
    const [remainMonthSalesGoalParse1,setRemainMonthSalesGoalParse1] = useState("");

    useEffect(() => {
        async function fetchData() {
            try {
                const salesGoalData = await getSalesGoal({month, year}); // 비동기 함수 호출
                setYearGoalContrast(salesGoalData.data.yearGoalContrast);
                setMonthGoalContrast(salesGoalData.data.monthGoalContrast);
                setRemainYearSalesGoal(salesGoalData.data.remainYearSalesGoal);

                setRemainMonthSalesGoal(salesGoalData.data.remainMonthSalesGoal);
                setRemainMonthSalesGoalParse1(Number(salesGoalData.data.remainMonthSalesGoal/10000000).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

                setYearSalesGoal(salesGoalData.data.yearSalesGoal);

                setMonthSalesGoal(salesGoalData.data.monthSalesGoal);
                setMonthSalesGoalParse1(Number(salesGoalData.data.monthSalesGoal/10000000).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

                setMonthSales(salesGoalData.data.monthSales);
                setMonthSalesParse1(Number(salesGoalData.data.monthSales/10000000).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

                setYearSales(salesGoalData.data.yearSales);
                setYearSaleParse1(Number(salesGoalData.data.yearSales/10000000).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

            } catch (error) {
                console.log(error); // 비동기 함수의 결과 처리
            }
        }

        fetchData();
    }, [month,mVal]);

    return (

        <Grid container justifyContent={'center'} spacing={2}>
            <Grid item xs={12} md={6} lg={4} sx={{textAlign: 'center'}}>
                <AppCurrentVisits
                    title={year.concat("년 목표 매출 달성률")}
                    subheader={"목표 매출 : ".concat((yearSalesGoal / 100000000).toString().split('.')[0]).concat("억원")}
                    chartData={[
                        {
                            label: '달성 매출 : '.concat(yearSalesParse1).concat("천만원"),
                            value: yearGoalContrast
                        },
                        {
                            label: '남은 목표 매출 : '.concat(remainYearSalesGoal / 100000000).toString().split('.')[0].concat("억원"),
                            value: 100 - yearGoalContrast
                        },
                    ]}
                    chartColors={[
                        theme.palette.info.main, theme.palette.error.main,
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{textAlign: 'center'}}>
                <AppCurrentVisits
                    title={mVal.toString().concat("월 목표 매출 달성률")}
                    subheader={"목표 매출 : ".concat(monthSalesGoalParse1).concat("천만원")}
                    chartData={[
                        {
                            label: '달성 매출 : '.concat(monthSalesParse1).concat("천만원"),
                            value: monthGoalContrast
                        },
                        {
                            label: '남은 목표 매출 : '.concat(remainMonthSalesGoalParse1).concat("천만원"),
                            value: 100 - monthGoalContrast
                        },
                    ]}
                    chartColors={[
                        theme.palette.info.main, theme.palette.error.main,
                    ]}
                />
            </Grid>
        </Grid>

    );

}